<template>
  <div class="background-validate content-page" @click="handleClick($event)">
    <InputTelephone
      ref="telephoneInput"
      :form="form"
      @ValidateTelephone="ValidateTelephone"
      :isValidate="$v.form.Telephone.$error"
      :v="$v.form.Telephone"
    />
    <ModalError ref="modalError" :text="successMessage" />
  </div>
</template>

<script>
import InputTelephone from "@/components/input/InputTelephone";
import ModalError from "@/components/alert-modal/ModalError";
import { required, minLength } from "vuelidate/lib/validators";
export default {
  components: {
    InputTelephone,
    ModalError
  },
  data() {
    return {
      form: {
        Telephone: "",
        SOCIALPROVIDER: "LINE",
        code: "TH",
        SOCIALID: "",
        x: "+66"
      },

      message: `หากคุณเคยลงทะเบียนผ่านสาขาของ ${process.env.VUE_APP_BRAND} อยู่แล้ว สามารถระบุเบอร์โทรศัพท์เพื่อเชื่อมต่อข้อมูลได้เลยค่ะ`,
      successMessage: "",
      prevRoute: "",
      isLoading: true
    };
  },
  validations() {
    return {
      form: {
        Telephone: { required, minLength: minLength(9) }
      }
    };
  },

  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (from.path && vm.$route.query.redirect != from.path) {
        vm.$router.replace({
          path: "/validatetelephone",
          query: {
            ...from.query,

            redirect: vm.$route.query.redirect || from.path
          }
        });
      }
    });
  },
  async created() {
    this.liffLogin();
  },
  methods: {
    handleClick(e) {
      if (
        !e.target.classList.contains("dropdown-select") &&
        e.target.tagName != "svg" &&
        e.target.id != "search-code" &&
        e.target.classList.contains != "dropdown-list"
      ) {
        if (this.$refs.telephoneInput.isShow) {
          this.$refs.telephoneInput.hideDropDown();
        }
      }
    },
    async liffLogin() {
      if (this.$liff.isLoggedIn()) {
        this.getProfileList();
      } else {
        this.$liff.login({
          redirectUri: `${this.$Endpoint}validatetelephone?redirect=${this.$route.query.redirect}`
        });
      }
    },
    getProfileList() {
      this.$liff.getProfile().then(profile => {
        this.$cookies.set("user_profile_token", profile, 60 * 60 * 24 * 30);
        // this.lineProfile = profile;
        this.LoginExternal(profile);
      });
    },
    async LoginExternal(profile) {
      const data = await this.$axios.post(
        `${process.env.VUE_APP_API}/api/v1/user/LoginExternal`,
        {
          SocialProvider: "line",
          SocialId: profile.userId
        }
      );

      this.isLoading = false;
      if (data.result == 1) {
        if (this.$route.query.redirect) {
          return this.$router.replace(this.$route.query.redirect);
        }
        return this.$router.replace("/profile");
      }
      // this.$cookies.set("hourglass_token", data.detail.token);
    },
    async ValidateTelephone() {
      try {
        this.$v.form.$touch();
        if (this.$v.form.$error) {
          return;
        }

        if (this.form.Telephone.length == 9 && this.form.Telephone[0] != 0) {
          this.form.Telephone = this.form.Telephone.padStart(
            this.form.Telephone.length + 1,
            "0"
          );
        }
        this.form.SOCIALID =
          this.$route.query.userId ||
          this.$cookies.get("user_profile_token").userId;
        this.$axios
          .post(
            `${process.env.VUE_APP_API}/api/v1/user/ValidateTelephone`,
            this.form
          )
          .then(async data => {
            if (data.result == 1) {
              // this.$store.commit("setEditPhoneNumber", false);
              this.$router.replace({
                path: "confirmotp",
                query: {
                  ...this.$route.query,
                  code: this.form.x,
                  tel: this.form.Telephone
                }
              });
            } else {
              this.successMessage = data.message;
              this.$refs.modalError.show();
            }
          });
      } catch (error) {
        window.alert(error.message + "error");
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.background-validate {
  // background-color: #fff;
  // position: fixed;
}
</style>
